import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className='relative min-h-[700vh] w-full max-w-[100vw] '>
      <div className='absolute  w-[100%]   z-10 flex justify-center items-center'>
        <div className='sat1 min-h-[100vh] pb-40 w-[90%] md:w-[80%] mt-[80px] md:mt-[200px] border-[10px] md:border-[20px] border-[#ffb03b] flex flex-col items-center'>
          <img className="h-[300px] rounded-full mt-20" src={require('./Assets/coin.gif')} />
          <p className=' font-bold text-3xl mt-10 text-center text-[#ffffff]'>UNKNOWN SATOSHI DEGEN TOKEN</p>
          <p className='text-white font-bold text-3xl mt-4 text-center'>$USDT</p>

          <div className='flex mt-10 justify-center gap-4'>
            <a href='https://x.com/USDT42069'>
              <div className='border-4 border-black bg-yellow-600 px-4 py-2 text-black'>
                <p>Twitter</p>
              </div>
            </a>
            <a href='https://t.me/USDT42069Portal'>
              <div className='border-4 border-black bg-yellow-600 px-4 py-2 text-black'>
                <p>Telegram</p>
              </div>
            </a>
          </div>
          <div className='my-6'>
              <video autoPlay muted loop id="myVideo" className='w-full h-full' src={require('./Assets/memes/trumpani.mp4')} />
            
          </div>
          <p className='text-white font-bold text-3xl mt-10 mb-6'>TOKENOMICS</p>
          <div className='flex items-center  flex-col gap-10'>
            <div className='flex justify-center h-[146px] w-[300px]  md:h-[232px] md:w-[500px] items-center t1'>
              <p className='text-white font-bold text-3xl mt-10'>LP Burned</p>
            </div>
            <div className='flex justify-center h-[300px] w-[300px] items-center t2'>
              <p className='text-white font-bold text-3xl mt-10'>Supply 1 Billion</p>
            </div>
            <div className='flex justify-center h-[300px] w-[300px] items-center t3'>
              <p className='text-white font-bold text-3xl mt-10'>0% Tax</p>
            </div>
          </div>
          <div className='mt-10'>
            <p className='text-white text-center font-bold text-3xl mt-10 mb-6'>6,900 Traders storm the NYSE trading floor to proclaim that 6900 is better than 500</p>
          </div>
          <div className='mt-20'>
            <p className='text-white text-center font-bold text-3xl mt-10 mb-6'>$USDT MEMES</p>
            <div className='flex flex-wrap justify-center gap-8 '>
              <img src={require('./Assets/memes/m1.gif')} />
              
              <img src={require('./Assets/memes/2.webp')} />
              <img src={require('./Assets/memes/3.webp')} />
              <img src={require('./Assets/memes/4.png')} />
              <img src={require('./Assets/memes/m2.gif')} />
              <img src={require('./Assets/memes/m3.gif')} />
            </div>
          </div>
        </div>
      </div>
      <video autoPlay muted loop id="myVideo" className='absolute object-cover w-full h-full z-1' src={require('./Assets/video.mp4')} />

    </div>
  );
}

export default App;
